<template>
  <v-app>
    <v-container
      id="login-account"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                Page Not Found
              </div>

              <div class="subtitle-1 font-weight-light">
                back to dashboard
              </div>
            </template>

            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <p class="mt-10" />
                    <v-btn
                      color="info"
                      href="#/view"
                      large
                    >
                      <v-icon left>
                        mdi-view-dashboard
                      </v-icon>
                      <span>Dashboard</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
      <button-view />
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'PageNotFound',

    components: {
      ButtonView: () => import('../dashboard/component/Buttons'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
